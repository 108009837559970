import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import { useNavigate } from "react-router-dom";

const translations = {
  en: {
    home: "Home",
    about: "About",
    services: "Services",
    contact: "Contact",
    tours: "Tours",
  },
  ko: {
    home: "홈",
    about: "소개",
    services: "서비스",
    contact: "문의하기",
    tours: "투어",
  },
};

const Header = () => {
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // body 스크롤 제어
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden"; // 메뉴 열릴 때 배경 스크롤 막기
    } else {
      document.body.style.overflow = "unset"; // 메뉴 닫히면 스크롤 다시 활성화
    }
  }, [isMenuOpen]);

  const t = translations[language];

  const handleNavClick = (path: string) => {
    navigate(path);
    setIsMenuOpen(false); // 네비게이션 후 메뉴 닫기
    window.scrollTo({ top: 0, behavior: "smooth" }); // 페이지 상단으로 스크롤
  };

  return (
    <HeaderContainer isScrolled={isScrolled}>
      <HeaderContent>
        <Logo
          isScrolled={isScrolled}
          onClick={() => handleNavClick("/")}
          style={{ cursor: "pointer" }}
        >
          Moment Korea Tour
        </Logo>
        <NavMenu isOpen={isMenuOpen} isScrolled={isScrolled}>
          <NavItem isScrolled={isScrolled} onClick={() => handleNavClick("/")}>
            {t.home}
          </NavItem>
          <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/about")}
          >
            {t.about}
          </NavItem>
          <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/tours")}
          >
            {t.tours}
          </NavItem>
          <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/contact")}
          >
            {t.contact}
          </NavItem>
          <StyledLanguageButton
            isScrolled={isScrolled}
            onClick={() => {
              setLanguage(language === "en" ? "ko" : "en");
              setIsMenuOpen(false);
            }}
          >
            {language === "en" ? "KO" : "EN"}
          </StyledLanguageButton>
        </NavMenu>
        <MenuButton
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          isOpen={isMenuOpen}
        >
          <span />
          <span />
          <span />
        </MenuButton>
      </HeaderContent>
      {isMenuOpen && <Overlay onClick={() => setIsMenuOpen(false)} />}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header<{ isScrolled: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ isScrolled }) =>
    isScrolled ? "rgba(0, 0, 0, 0.95)" : "transparent"};
  box-shadow: ${({ isScrolled }) =>
    isScrolled ? "0 2px 10px rgba(0, 0, 0, 0.1)" : "none"};
  z-index: 1000;
  transition: all 0.3s ease;
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div<{ isScrolled: boolean }>`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ isScrolled }) => (isScrolled ? "#fff" : "#fff")};
  z-index: 1000;
`;

const NavMenu = styled.nav<{ isOpen: boolean; isScrolled: boolean }>`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: ${breakpoints.mobile}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    /* background: ${({ isScrolled }) =>
      isScrolled ? "rgba(0, 0, 0, 0.527)" : "rgba(0, 0, 0, 0.377)"}; */
    backdrop-filter: blur(10px);
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: all 0.7s ease;
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    z-index: 999;
    pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
    overflow: hidden;
  }
`;

const NavItem = styled.a<{ isScrolled: boolean }>`
  text-decoration: none;
  color: ${({ isScrolled }) => (isScrolled ? "#fff" : "#fff")};
  font-weight: 500;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #ffd700;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1rem;
    width: 100%;
    text-align: center;
  }
`;

const StyledLanguageButton = styled.button<{ isScrolled: boolean }>`
  padding: 0.5rem 1rem;
  border: 2px solid #ffd700;
  background: transparent;
  color: ${({ isScrolled }) => (isScrolled ? "#fff" : "#fff")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #ffd700;
    color: black;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1rem 2rem;
    margin-top: 1rem;
  }
`;
const MenuButton = styled.button<{ isOpen: boolean }>`
  display: none;
  flex-direction: column;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  z-index: 2000;
  position: relative;

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
  }

  span {
    width: 24px;
    height: 2px;
    background: #fff;
    transition: all 0.3s ease;
    position: relative;

    &:first-child {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(45deg) translate(6px, 5px)" : "rotate(0)"};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }

    &:last-child {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(-45deg) translate(6px, -5px)" : "rotate(0)"};
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.574);
  backdrop-filter: blur(10px);
  z-index: 900;
  display: none;

  @media (max-width: ${breakpoints.mobile}) {
    display: block;
    pointer-events: auto;
  }
`;

export default Header;
