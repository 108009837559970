import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import { useLocation } from "react-router-dom";

type Season = "spring" | "summer" | "autumn" | "winter";
type Language = "ko" | "en";

interface TourInfo {
  title: string;
  period: string;
  price: string;
  highlights: string[];
  includes: string[];
  description: string;
}

interface SeasonTourInfo {
  spring: TourInfo;
  summer: TourInfo;
  autumn: TourInfo;
  winter: TourInfo;
}

interface SeasonTours {
  ko: SeasonTourInfo;
  en: SeasonTourInfo;
}

interface SeasonImages {
  spring: string[];
  summer: string[];
  autumn: string[];
  winter: string[];
}

const Tour = () => {
  const location = useLocation();
  const { language } = useLanguage();
  const [selectedSeason, setSelectedSeason] = useState<Season>(
    (location.state?.selectedSeason as Season) || "spring"
  );

  useEffect(() => {
    if (location.state?.selectedSeason === "winter") {
      const winterSection = document.getElementById("winter-section");
      if (winterSection) {
        winterSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);

  const seasonImages: SeasonImages = {
    spring: [
      "/images/spring-1.jpg",
      "/images/spring-2.jpg",
      "/images/spring-3.jpg",
    ],
    summer: [
      "/images/summer-1.jpg",
      "/images/summer-2.jpg",
      "/images/summer-3.jpg",
    ],
    autumn: [
      "/images/autumn-1.jpg",
      "/images/autumn-2.jpg",
      "/images/autumn-3.jpg",
    ],
    winter: [
      "/images/9-9.webp",
      "/images/winter-2.jpg",
      "/images/winter-3.jpg",
    ],
  };

  const tours: SeasonTours = {
    en: {
      spring: {
        title: "Spring Season Tour",
        period: "March - May",
        price: "₩2,500,000 onwards",
        highlights: [
          "Peach Blossom Road Drive Tour",
          "Jeju Island Coast Road Luxury Drive",
          "VIP Peach Blossom Festival Tour",
        ],
        includes: [
          "Luxury Sports Car Rental",
          "5-star Hotel Accommodation",
          "Michelin Star Restaurant Meal",
          "Professional Guide",
        ],
        description:
          "Experience the romance of spring with premium driving. Enjoy the beautiful spring scenery of Korea with luxury sports cars.",
      },
      summer: {
        title: "Summer Season Tour",
        period: "June - August",
        price: "₩2,800,000 onwards",
        highlights: [
          "Mountain Road Drive in Gangwon Province",
          "East Sea Coast Line Drive",
          "Night City Drive",
        ],
        includes: [
          "High-performance luxury car",
          "Luxury resort accommodation",
          "Private yacht tour",
          "VIP concierge service",
        ],
        description:
          "Experience the luxury driving with the cool sea breeze. Enjoy the special experience from the night drive in the city to the scenic views of the coast.",
      },
      autumn: {
        title: "Autumn Season Tour",
        period: "September - November",
        price: "₩2,700,000 onwards",
        highlights: [
          "Peach Blossom Road Drive",
          "Wine Road Drive Experience",
          "National Peach Blossom Sites Tour",
        ],
        includes: [
          "Super Car Drive",
          "Boutique Hotel Accommodation",
          "Wine Tasting & Tour",
          "Professional Photographer",
        ],
        description:
          "Enjoy the special journey with the golden autumn. Explore the beautiful peach blossom sites of Korea with luxury cars.",
      },
      winter: {
        title: "Winter Season Tour",
        period: "December - February",
        price: "₩3,000,000 onwards",
        highlights: [
          "Snow Drive Course",
          "Ski Resort VIP Tour",
          "Winter Hot Spring Luxury Package",
        ],
        includes: [
          "4-wheel drive luxury SUV",
          "Ski Resort Sweet Room",
          "Spa Experience",
          "Helicopter Tour",
        ],
        description:
          "Experience the premium driving in the snowy winter. Enjoy the special tour with winter leisure and luxury.",
      },
    },
    ko: {
      spring: {
        title: "봄 시즌 투어",
        period: "3월 - 5월",
        price: "₩2,500,000부터",
        highlights: [
          "벚꽃 로드 드라이빙 투어",
          "제주도 해안도로 럭셔리 드라이브",
          "남해안 벚꽃 페스티벌 VIP 투어",
        ],
        includes: [
          "최고급 스포츠카 렌탈",
          "5성급 호텔 숙박",
          "미쉐린 스타 레스토랑 식사",
          "전문 가이드 동행",
        ],
        description:
          "봄의 낭만과 함께하는 프리미엄 드라이빙 경험. 한국의 아름다운 봄 풍경을 럭셔리 스포츠카와 함께 즐겨보세요.",
      },
      summer: {
        title: "여름 시즌 투어",
        period: "6월 - 8월",
        price: "₩2,800,000부터",
        highlights: [
          "강원도 산악 드라이빙 코스",
          "동해안 선셋 드라이브",
          "야간 도심 스포츠카 투어",
        ],
        includes: [
          "고성능 럭셔리 차량",
          "프리미엄 리조트 숙박",
          "프라이빗 요트 투어",
          "VIP 컨시어지 서비스",
        ],
        description:
          "시원한 바다와 함께하는 럭셔리 드라이빙. 한여름 밤의 도심 드라이브부터 해안가의 절경까지 특별한 경험을 제공합니다.",
      },
      autumn: {
        title: "가을 시즌 투어",
        period: "9월 - 11월",
        price: "₩2,700,000부터",
        highlights: [
          "단풍길 프리미엄 드라이브",
          "와인딩 로드 스포츠카 체험",
          "전국 단풍 명소 투어",
        ],
        includes: [
          "슈퍼카 드라이빙",
          "부티크 호텔 숙박",
          "와이너리 투어 & 테이스팅",
          "전문 포토그래퍼 동행",
        ],
        description:
          "황금빛으로 물드는 가을과 함께하는 특별한 여정. 한국의 아름다운 단풍 명소를 최고급 차량과 함께 둘러보세요.",
      },
      winter: {
        title: "겨울 시즌 투어",
        period: "12월 - 2월",
        price: "₩3,000,000부터",
        highlights: [
          "설경 드라이빙 코스",
          "스키 리조트 VIP 투어",
          "겨울 온천 럭셔리 패키지",
        ],
        includes: [
          "4륜구동 럭셔리 SUV",
          "스키 리조트 스위트룸",
          "프라이빗 스파 체험",
          "헬리콥터 투어",
        ],
        description:
          "하얀 설경 속 프리미엄 드라이빙 체험. 겨울 레저와 럭셔리한 휴식이 함께하는 특별한 투어입니다.",
      },
    },
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  const renderHighlights = (highlight: string, index: number): JSX.Element => (
    <ListItem key={index}>
      <Dot />
      {highlight}
    </ListItem>
  );

  const renderIncludes = (item: string, index: number): JSX.Element => (
    <ListItem key={index}>
      <Dot />
      {item}
    </ListItem>
  );

  const buttonText = {
    ko: "투어 예약하기",
    en: "Book This Tour",
  };

  return (
    <Container>
      <SliderWrapper>
        <StyledSlider {...sliderSettings}>
          {seasonImages[selectedSeason].map((image: string, index: number) => (
            <SlideImage key={index}>
              <img src={image} alt={`${selectedSeason} tour ${index + 1}`} />
            </SlideImage>
          ))}
        </StyledSlider>
      </SliderWrapper>

      <Title>Seasonal Tours</Title>
      <SeasonSelector>
        {(Object.keys(tours[language as Language]) as Season[]).map(
          (season) => (
            <SeasonButton
              key={season}
              isSelected={selectedSeason === season}
              onClick={() => setSelectedSeason(season)}
            >
              {tours[language as Language][season].title}
            </SeasonButton>
          )
        )}
      </SeasonSelector>

      <TourInfo>
        <SeasonTitle>
          {tours[language as Language][selectedSeason].title}
        </SeasonTitle>
        <Period>{tours[language as Language][selectedSeason].period}</Period>
        <Price>{tours[language as Language][selectedSeason].price}</Price>

        <Section>
          <SectionTitle>Tour Highlights</SectionTitle>
          <List>
            {tours[language as Language][selectedSeason].highlights.map(
              renderHighlights
            )}
          </List>
        </Section>

        <Section>
          <SectionTitle>Includes</SectionTitle>
          <List>
            {tours[language as Language][selectedSeason].includes.map(
              renderIncludes
            )}
          </List>
        </Section>

        <Description>
          {tours[language as Language][selectedSeason].description}
        </Description>

        <BookButton>{buttonText[language as Language]}</BookButton>
      </TourInfo>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  color: #fff;
  padding: 120px 20px;
`;

const Title = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SeasonSelector = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const SeasonButton = styled.button<{ isSelected: boolean }>`
  padding: 1rem 2rem;
  background: ${({ isSelected }) =>
    isSelected ? "rgba(255, 215, 0, 0.1)" : "transparent"};
  border: 2px solid
    ${({ isSelected }) => (isSelected ? "#ffd700" : "rgba(255, 255, 255, 0.3)")};
  color: ${({ isSelected }) => (isSelected ? "#ffd700" : "#fff")};
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: #ffd700;
    color: #ffd700;
  }
`;
//eslint-disable-next-line
const TourInfo = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  border: 1px solid rgba(255, 215, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
`;

const SeasonTitle = styled.h2`
  font-size: 2.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
  text-align: center;
`;

const Period = styled.p`
  text-align: center;
  color: #ccc;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const Price = styled.p`
  text-align: center;
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
`;

const Section = styled.div`
  margin: 2rem 0;
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 215, 0, 0.2);
  padding-bottom: 0.5rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  color: #fff;
  font-size: 1.1rem;
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background: #ffd700;
  border-radius: 50%;
  margin-right: 1rem;
`;

const Description = styled.p`
  color: #ccc;
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 2rem 0;
  text-align: center;
  font-style: italic;
`;

const BookButton = styled.button`
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 3rem auto 0;
  padding: 1.2rem 2.5rem;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  border: none;
  border-radius: 50px;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }
`;

const SliderWrapper = styled.div`
  margin: -120px -20px 3rem -20px;
  height: 60vh;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #000000, transparent);
    pointer-events: none;
  }
`;

const StyledSlider = styled(Slider)`
  height: 100%;

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-dots {
    bottom: 30px;
    z-index: 1;

    li button:before {
      color: #ffd700;
    }

    li.slick-active button:before {
      color: #ffd700;
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;

    &:before {
      font-size: 40px;
    }
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }
`;

const SlideImage = styled.div`
  height: 60vh;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export default Tour;
