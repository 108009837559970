import React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import { FaInstagram, FaFacebookF } from "react-icons/fa";

type LanguageType = "en" | "ko";
type TranslationType = {
  [key in LanguageType]: {
    address: string;
    contact: string;
    links: string;
    rights: string;
    privacy: string;
    terms: string;
  };
};

const translations: TranslationType = {
  en: {
    address: "123 Teheran-ro, Gangnam-gu, Seoul",
    contact: "Contact",
    links: "Quick Links",
    rights: "All Rights Reserved",
    privacy: "Privacy Policy",
    terms: "Terms of Service",
  },
  ko: {
    address: "서울특별시 강남구 테헤란로 123",
    contact: "연락처",
    links: "바로가기",
    rights: "All Rights Reserved",
    privacy: "개인정보처리방침",
    terms: "이용약관",
  },
};

const socialLinks = {
  instagram: "https://www.instagram.com/momentkoreatour/",
  //   youtube: "https://www.youtube.com/@momentkoreatour",
  facebook: "https://www.facebook.com/momentkoreatour",
};

const Footer = () => {
  const { language } = useLanguage() as { language: LanguageType };
  const t = translations[language];

  return (
    <FooterContainer>
      <FooterWrapper>
        <MainContent>
          <BrandSection>
            <Logo>Moment Korea Tour</Logo>
            <Address>{t.address}</Address>
            <SocialIcons>
              <SocialIconLink
                href={socialLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href={socialLinks.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </SocialIconLink>
            </SocialIcons>
          </BrandSection>

          <LinksSection>
            <Column>
              <ColumnTitle>{t.contact}</ColumnTitle>
              <ColumnLink>+82 2-1234-5678</ColumnLink>
              <ColumnLink>info@momentkorea.com</ColumnLink>
            </Column>
            <Column>
              <ColumnTitle>{t.links}</ColumnTitle>
              <ColumnLink href="#">{t.privacy}</ColumnLink>
              <ColumnLink href="#">{t.terms}</ColumnLink>
            </Column>
          </LinksSection>
        </MainContent>

        <Divider />

        <BottomBar>
          <CopyrightText>© 2024 Moment Korea Tour. {t.rights}</CopyrightText>
        </BottomBar>
      </FooterWrapper>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #000000;
  color: #ffffff;
  padding: 5rem 0 2rem;
  width: 100%;
`;

const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  gap: 4rem;

  @media (max-width: ${breakpoints.mobile}) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const BrandSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: ${breakpoints.mobile}) {
    align-items: center;
    text-align: center;
  }
`;

const Logo = styled.div`
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
`;

const Address = styled.div`
  color: #888;
  font-size: 0.95rem;
  line-height: 1.6;

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 80%;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1.2rem;
  margin-top: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
  }
`;

const SocialIconLink = styled.a`
  color: #ffffff;
  font-size: 1.4rem;
  transition: all 0.3s ease;

  &:hover {
    color: #ffffff;
    transform: translateY(-3px);
  }
`;

const LinksSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  @media (max-width: ${breakpoints.mobile}) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ColumnTitle = styled.h3`
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    width: 2.5rem;
    height: 1px;
    background: #aaaaaa;

    @media (max-width: ${breakpoints.mobile}) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const ColumnLink = styled.a<{ href?: string }>`
  color: #888;
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: linear-gradient(to right, transparent, #333, transparent);
  margin: 3rem 0;
`;

const BottomBar = styled.div`
  text-align: center;
`;

const CopyrightText = styled.p`
  color: #666;
  font-size: 0.5rem;
`;

export default Footer;
